import React from "react"
import CardlessPayments from "../../components/body/pages/en-ng/cardless-payments"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CardlessPaymentsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/cardless-payments/"}
      title="Go Cardless! Easy & Secure Payments | Kuda"
      description="Experience the convenience of cardless payments with Kuda. Make secure transactions anytime, anywhere, right from your mobile device. Join Kuda and go cardless today!"
    />
    <CardlessPayments />
  </Layout>
)

export default CardlessPaymentsPage
